import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home', 
    component: () => import(/* webpackChunkName: 'home' */ '../views/Home.vue')
  },
  {
    path: '/sugerencias',
    name: 'Sugerencias',
    component: () => import(/* webpackChunkName: 'sugerencias' */ '../views/Sugerencias.vue')
  },
  // {
  //   path: '/pruebas',
  //   name: 'pruebas',
  //   component: () => import(/* webpackChunkName: 'pruebas' */ '../components/ArtFind.vue')
  // },
  // {
  //   path: '/pruebas-front',
  //   name: 'pruebas-front',
  //   component: () => import(/* webpackChunkName: 'pruebas-front' */ '../views/PruebasFront.vue')
  // },
  {
    path: '/extranet-martin',
    name: 'extranet-martin',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda', 'departamento']
    } },
    component: () => import(/* webpackChunkName: 'extranet-martin' */ '../views/ExtranetMartin.vue')
  },
  {
    path: '/enviar-mensajes',
    name: 'enviar-mensajes',
    component: () => import(/* webpackChunkName: 'enviar-mensajes' */ '../views/MensajesEnviados.vue')
  },
  {
    path: '/enviar-mensajes-edicion',
    name: 'enviar-mensajes-edicion',
    component: () => import(/* webpackChunkName: 'enviar-mensajes-edicion' */ '../views/MensajesEnviadosEdicion.vue')  
  },
  {
    path: '/recibir-mensajes',
    name: 'recibir-mensajes',
    props: { tipomensajes: 'Aux', msjcat: {MsjCatId: 2, MsjCatNom: 'PROMOCIONES'}},
    component: () => import(/* webpackChunkName: 'recibir-mensajes' */ '../views/MensajesRecibidos.vue')
  },
  {
    path: '/recibir-mensajes-pral-0',
    name: 'recibir-mensajes-pral-0',
    props: { tipomensajes: 'Pral', msjcat: {MsjCatId: 0, MsjCatNom: ''}},
    component: () => import('../views/MensajesRecibidos.vue')
  },
  {
    path: '/recibir-mensajes-pral-1',
    name: 'recibir-mensajes-pral-1',
    props: { tipomensajes: 'Pral', msjcat: {MsjCatId: 1, MsjCatNom: 'PRODUCTOS'}},
    component: () => import('../views/MensajesRecibidos.vue')
  },
  {
    path: '/recibir-mensajes-pral-2',
    name: 'recibir-mensajes-pral-2',
    props: { tipomensajes: 'Pral', msjcat: {MsjCatId: 2, MsjCatNom: 'PROMOCIONES'}},
    component: () => import('../views/MensajesRecibidos.vue')
  },
  {
    path: '/recibir-mensajes-pral-3',
    name: 'recibir-mensajes-pral-3',
    props: { tipomensajes: 'Pral', msjcat: {MsjCatId: 3, MsjCatNom: 'PROVEEDORES'}},
    component: () => import('../views/MensajesRecibidos.vue')
  },
  {
    path: '/recibir-mensajes-pral-4',
    name: 'recibir-mensajes-pral-4',
    props: { tipomensajes: 'Pral', msjcat: {MsjCatId: 4, MsjCatNom: 'AVISOS Y GESTIÓN DE TIENDA'}},
    component: () => import('../views/MensajesRecibidos.vue')
  },
  {
    path: '/recibir-mensajes-pral-5',
    name: 'recibir-mensajes-pral-5',
    props: { tipomensajes: 'Pral', msjcat: {MsjCatId: 5, MsjCatNom: 'CARTELERIA'}},
    component: () => import('../views/MensajesRecibidos.vue')
  },
  {
    path: '/recibir-mensajes-aux-0',
    name: 'recibir-mensajes-aux-0',
    props: { tipomensajes: 'Aux', msjcat: {MsjCatId: 0, MsjCatNom: ''}},
    component: () => import('../views/MensajesRecibidos.vue')
  },
  {
    path: '/recibir-mensajes-aux-1',
    name: 'recibir-mensajes-aux-1',
    props: { tipomensajes: 'Aux', msjcat: {MsjCatId: 1, MsjCatNom: 'PRODUCTOS'}},
    component: () => import('../views/MensajesRecibidos.vue')
  },
  {
    path: '/recibir-mensajes-aux-2',
    name: 'recibir-mensajes-aux-2',
    props: { tipomensajes: 'Aux', msjcat: {MsjCatId: 2, MsjCatNom: 'PROMOCIONES'}},
    component: () => import('../views/MensajesRecibidos.vue')
  },
  {
    path: '/recibir-mensajes-aux-3',
    name: 'recibir-mensajes-aux-3',
    props: { tipomensajes: 'Aux', msjcat: {MsjCatId: 3, MsjCatNom: 'PROVEEDORES'}},
    component: () => import('../views/MensajesRecibidos.vue')
  },
  {
    path: '/recibir-mensajes-aux-4',
    name: 'recibir-mensajes-aux-4',
    props: { tipomensajes: 'Aux', msjcat: {MsjCatId: 4, MsjCatNom: 'AVISOS Y GESTIÓN DE TIENDA'}},
    component: () => import('../views/MensajesRecibidos.vue')
  },
  {
    path: '/recibir-mensajes-aux-5',
    name: 'recibir-mensajes-aux-5',
    props: { tipomensajes: 'Aux', msjcat: {MsjCatId: 5, MsjCatNom: 'CARTELERIA'}},
    component: () => import('../views/MensajesRecibidos.vue')
  },
  {
    path: '/recibir-mensajes-edicion',
    name: 'recibir-mensajes-edicion',
    component: () => import(/* webpackChunkName: 'recibir-mensajes-edicion' */ '../views/MensajesRecibidosEdicion.vue') 
  },
  {
    path: '/cuadrantes-tienda',
    name: 'cuadrantes-tienda',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda', 'departamento']
    } },
    component: () => import(/* webpackChunkName: 'cuadrantes-tienda' */ '../views/CuadrantesTienda.vue')
  },
  {
    path: '/cuadrantes-empleado',
    name: 'cuadrantes-empleado',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['persona', 'departamento']
    } },
    component: () => import(/* webpackChunkName: 'cuadrantes-empleado' */ '../views/CuadrantesEmpleado.vue')
  },
  {
    path: '/cuadrantes-gestion',
    name: 'cuadrantes-gestion',
    meta: { roles: {
      development: true,
      andOr: 'AND',
      list: []
    } },
    component: () => import('../views/CuadrantesGestion.vue')
  },
  {
    path: '/fichaje',
    name: 'fichaje',
    meta: { roles: {
      development: false,
      andOr: 'AND',
      list: ['tienda']
    } },
    component: () => import(/* webpackChunkName: 'fichaje' */ '../views/Fichaje.vue')
  },
  {
    path: '/fichajes-tienda',
    name: 'fichajes-tienda',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['persona', 'departamento', 'tienda']
    } },
    component: () => import(/* webpackChunkName: 'fichajes-tienda' */ '../views/FichajesTienda.vue')
  },
  {
    path: '/documentos-pendientes-firmar',
    name: 'documentos-pendientes-firmar',
    meta: { roles: {
      development: false,
      andOr: 'AND',
      list: ['persona']
    } },
    component: () => import(/* webpackChunkName: 'documentos-pendientes-firmar' */ '../views/DocumentosPendientesFirmar.vue')
  },
  {
    path: '/documento-firmar',
    name: 'documento-firmar',
    meta: { roles: {
      development: false,
      andOr: 'AND',
      list: ['persona']
    } },
    component: () => import(/* webpackChunkName: 'documento-firmar' */ '../views/DocumentoFirmar.vue')
  },
  {
    path: '/nominas-consulta',
    name: 'nominas-consulta',
    meta: { roles: {
      development: true,
      andOr: 'AND',
      list: ['persona']
    } },
    component: () => import('../views/NominasConsulta.vue')
  },
  {
    path: '/nominas-consulta/:file',
    name: 'nominas-consulta-detalle',
    meta: { roles: {
      development: true,
      andOr: 'AND',
      list: ['persona']
    } },
    component: () => import('../views/NominasConsultaDetalle.vue')
  },
  {
    path: '/documentos-empresa',
    name: 'documentos-empresa',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['persona', 'departamento', 'tienda']
    } },
    component: () => import(/* webpackChunkName: 'documentos-empresa' */ '../views/DocumentosEmpresa.vue')
  },
  {
    path: '/documentos-empresa-visualizacion',
    name: 'documentos-empresa-visualizacion',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import(/* webpackChunkName: 'documentos-empresa' */ '../views/DocumentosEmpresaVisualizados.vue')
  },
  {
    path: '/visor-documentos',
    name: 'visor-documentos',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento', 'tienda', 'persona']
    } },
    component: () => import(/* webpackChunkName: 'visor-documentos' */ '../views/VisorDocumentos.vue')
  },
  {
    path: '/documentos-consulta',
    name: 'documentos-consulta',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['persona']
    } },
    component: () => import('../views/DocumentosConsulta.vue')
  },
  {
    path: '/documentos-consulta/:firmado/:cod',
    name: 'documentos-consulta-detalle',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['persona']
    } },
    component: () => import('../views/DocumentosConsultaDetalle.vue')
  },
  {
    path: '/control-temperatura',
    name: 'control-temperatura',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import(/* webpackChunkName: 'control-temperatura' */ '../views/ControlTemperatura.vue')
  },
  {
    path: '/control-temperatura2',
    name: 'control-temperatura2',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import(/* webpackChunkName: 'control-temperatura2' */ '../views/CtrlTemperatura.vue')
  },
  {
    path: '/control-temperatura-edicion',
    name: 'control-temperatura-edicion',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import(/* webpackChunkName: 'control-temperatura-edicion' */ '../views/CtrlTemperaturaEdicion.vue')
  },
  {
    path: '/control-trazabilidad',
    name: 'control-trazabilidad',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import(/* webpackChunkName: 'control-trazabilidad' */ '../views/ControlTrazabilidad.vue'),
  },
  {
    path: '/control-trazabilidad2',
    name: 'control-trazabilidad2',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import(/* webpackChunkName: 'control-trazabilidad2' */ '../components/TrazList.vue'),
  },
  {
    path: '/control-trazabilidad-edicion',
    name: 'control-trazabilidad-edicion',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import(/* webpackChunkName: 'control-trazabilidad-edicion' */ '../components/TrazEdit.vue')
  },
  {
    path: '/consulta-trazabilidad',
    name: 'consulta-trazabilidad',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import(/* webpackChunkName: 'consulta-trazabilidad' */ '../views/ConsultaTrazabilidad.vue')
  },
  {
    path: '/consulta-trazabilidad2',
    name: 'consulta-trazabilidad2',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import(/* webpackChunkName: 'consulta-trazabilidad2' */ '../views/ConsultaTrazabilidad2.vue')
  },
  {
    path: '/control-limpieza',
    name: 'control-limpieza',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda', 'departamento']
    } },
    component: () => import('../views/ControlLimpieza.vue'),
  },
  {
    path: '/control-limpieza-nuevo',
    name: 'control-limpieza-nuevo',
    meta: { 
      isNew: true, 
      roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda', 'departamento']
    } },
    component: () => import('../views/ControlLimpiezaEditar.vue')
  },
  {
    path: '/control-limpieza-editar/:id',
    name: 'control-limpieza-editar',
    meta: { isNew: false, 
      roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda', 'departamento']
    } },
    component: () => import('../views/ControlLimpiezaEditar.vue')
  },
  {
    path: '/control-dinero',
    name: 'control-dinero',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import('../views/ControlDinero.vue'),
  },
  {
    path: '/control-dinero-nuevo',
    name: 'control-dinero-nuevo',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import('../views/ControlDineroEditar.vue')
  },
  {
    path: '/control-dinero-editar/:id',
    name: 'control-dinero-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import('../views/ControlDineroEditar.vue')
  },
  {
    path: '/control-carteleria',
    name: 'control-carteleria',
    meta: { isNew: false },
    component: () => import('../views/ControlCarteleria.vue')
  },
  {
    path: '/incidencias',
    name: 'incidencias',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento', 'tienda', 'persona']
    } },
    component: () => import('../views/Incidencias/Incidencias.vue'),
  },
  {
    path: '/incidencia-nuevo',
    name: 'incidencia-nuevo',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento', 'tienda', 'persona']
    } },
    component: () => import('../views/Incidencias/IncidenciasEditar.vue')
  },
  {
    path: '/incidencia-editar/:id',
    name: 'incidencia-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento', 'tienda', 'persona']
    } },
    component: () => import('../views/Incidencias/IncidenciasEditar.vue')
  },
  {
    path: '/mantenimiento/incidencias',
    name: 'mantenimiento-incidencias',
    meta: { isProveedor: false, roles: {
      development: true,
      andOr: 'OR',
      list: ['persona']
    } },
    component: () => import('../views/Incidencias/Mantenimiento/IncidenciasMantenimiento.vue'),
  },
  {
    path: '/mantenimiento/incidencia-editar/:id',
    name: 'mantenimiento-incidencia-editar',
    meta: { isNew: false, isProveedor: false, roles: {
      development: true,
      andOr: 'OR',
      list: ['persona']
    } },
    component: () => import('../views/Incidencias/Mantenimiento/IncidenciasMantenimientoEditar.vue')
  },
  {
    path: '/proveedor/incidencias',
    name: 'proveedor-incidencias',
    meta: { isProveedor: true, roles: {
      development: true,
      andOr: 'OR',
      list: ['partner']
    } },
    component: () => import('../views/Incidencias/Mantenimiento/IncidenciasMantenimiento.vue'),
  },
  {
    path: '/proveedor/incidencia-editar/:id',
    name: 'proveedor-incidencia-editar',
    meta: { isNew: false, isProveedor: true, roles: {
      development: true,
      andOr: 'OR',
      list: ['partner']
    } },
    component: () => import('../views/Incidencias/Mantenimiento/IncidenciasMantenimientoEditar.vue')
  },
  {
    path: '/categorias-incidencia',
    name: 'categorias-incidencia',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/CategoriasIncidencia.vue'),
  },
  {
    path: '/categoria-incidencia-nueva',
    name: 'categoria-incidencia-nueva',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/CategoriasIncidenciaEditar.vue')
  },
  {
    path: '/categoria-incidencia-editar/:id',
    name: 'categoria-incidencia-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/CategoriasIncidenciaEditar.vue')
  },
  {
    path: '/presupuesto',
    name: 'presupuesto',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import('../views/Presupuestos.vue')
  },
  {
    path: '/Gestion-Rgpd',
    name: 'gestion-rgpd',
    component: () => import(/* webpackChunkName: 'gestion-rgpd' */ '../views/GestionRgpd.vue')
  },
  {
    path: '/materiales',
    name: 'materiales',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/Materiales.vue')
  },
  {
    path: '/material-nuevo',
    name: 'material-nuevo',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/MaterialesEditar.vue')
  },
  {
    path: '/material-editar/:id',
    name: 'material-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/MaterialesEditar.vue')
  },
  {
    path: '/categorias-material',
    name: 'categorias-material',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/CategoriasMaterial.vue')
  },
  {
    path: '/categorias-material-nueva',
    name: 'categorias-material-nueva',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/CategoriasMaterialEditar.vue')
  },
  {
    path: '/categorias-material-editar/:id',
    name: 'categorias-material-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/CategoriasMaterialEditar.vue')
  },
  {
    path: '/variantes-material',
    name: 'variantes-material',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/VariantesMaterial.vue')
  },
  {
    path: '/variantes-material-nueva',
    name: 'variantes-material-nueva',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/VariantesMaterialEditar.vue')
  },
  {
    path: '/variantes-material-editar/:id',
    name: 'variantes-material-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/VariantesMaterialEditar.vue')
  },
  {
    path: '/articulos',
    name: 'articulos',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/Articulos.vue')
  },
  {
    path: '/articulo-nuevo',
    name: 'articulo-nuevo',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/ArticulosEditar.vue')
  },
  {
    path: '/articulo-editar/:id',
    name: 'articulo-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/ArticulosEditar.vue')
  },
  {
    path: '/categorias-articulo',
    name: 'categorias-articulo',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/CategoriasArticulo.vue')
  },
  {
    path: '/categorias-articulo-nueva',
    name: 'categorias-articulo-nueva',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/CategoriasArticuloEditar.vue')
  },
  {
    path: '/categorias-articulo-editar/:id',
    name: 'categorias-articulo-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/CategoriasArticuloEditar.vue')
  },
  {
    path: '/personas',
    name: 'personas',
    meta: { roles: {
      development: true,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/Personas.vue')
  },
  {
    path: '/persona-nueva',
    name: 'persona-nueva',
    meta: { isNew: true, roles: {
      development: true,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/PersonasEditar.vue')
  },
  {
    path: '/persona-editar/:id',
    name: 'persona-editar',
    meta: { isNew: false, roles: {
      development: true,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/PersonasEditar.vue')
  },
  {
    path: '/categorias-persona',
    name: 'categorias-persona',
    meta: { roles: {
      development: true,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/CategoriasPersona.vue')
  },
  {
    path: '/categorias-persona-nueva',
    name: 'categorias-persona-nueva',
    meta: { isNew: true, roles: {
      development: true,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/CategoriasPersonaEditar.vue')
  },
  {
    path: '/categorias-persona-editar/:id',
    name: 'categorias-persona-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/CategoriasPersonaEditar.vue')
  },
  {
    path: '/permisos-mensajeria',
    name: 'permisos-mensajeria',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/PermisosMensajeria.vue')
  },
  {
    path: '/permiso-mensajeria-nuevo',
    name: 'permiso-mensajeria-nuevo',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/PermisosMensajeriaEditar.vue')
  },
  {
    path: '/permiso-mensajeria-editar/:id',
    name: 'permiso-mensajeria-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/PermisosMensajeriaEditar.vue')
  },
  {
    path: '/almacenes',
    name: 'almacenes',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/Almacenes.vue')
  },
  {
    path: '/almacen-nuevo',
    name: 'almacen-nuevo',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/AlmacenesEditar.vue')
  },
  {
    path: '/almacen-editar/:id',
    name: 'almacen-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/AlmacenesEditar.vue')
  },
  {
    path: '/direcciones',
    name: 'direcciones',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/Direcciones.vue')
  },
  {
    path: '/direccion-nueva',
    name: 'direccion-nuevo',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/DireccionesEditar.vue')
  },
  {
    path: '/direccion-editar/:id',
    name: 'direccion-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/DireccionesEditar.vue')
  },
  {
    path: '/maquinas',
    name: 'maquinas',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/Maquinas.vue')
  },
  {
    path: '/maquina-nueva',
    name: 'maquina-nueva',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/MaquinasEditar.vue')
  },
  {
    path: '/maquina-editar/:id',
    name: 'maquina-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/MaquinasEditar.vue')
  },
  {
    path: '/estados-solicitud',
    name: 'estados-solicitud',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/EstadosSolicitud.vue')
  },
  {
    path: '/estado-solicitud-nuevo',
    name: 'estado-solicitud-nuevo',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/EstadosSolicitudEditar.vue')
  },
  {
    path: '/estado-solicitud-editar/:id',
    name: 'estado-solicitud-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/EstadosSolicitudEditar.vue')
  },
  {
    path: '/estados-vacaciones',
    name: 'estados-vacaciones',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/EstadosVacaciones.vue')
  },
  {
    path: '/estado-vacaciones-nuevo',
    name: 'estado-vacaciones-nuevo',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/EstadosVacacionesEditar.vue')
  },
  {
    path: '/estado-vacaciones-editar/:id',
    name: 'estado-vacaciones-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/EstadosVacacionesEditar.vue')
  },
  {
    path: '/periodos',
    name: 'periodos',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/Periodos.vue')
  },
  {
    path: '/periodo-nuevo',
    name: 'periodo-nuevo',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/PeriodosEditar.vue')
  },
  {
    path: '/periodo-editar/:id',
    name: 'periodo-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/PeriodosEditar.vue')
  },
  {
    path: '/elementos',
    name: 'elementos',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/Elementos.vue')
  },
  {
    path: '/elemento-nuevo',
    name: 'elemento-nuevo',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/ElementosEditar.vue')
  },
  {
    path: '/elemento-editar/:id',
    name: 'elemento-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/ElementosEditar.vue')
  },
  {
    path: '/categorias-elemento',
    name: 'categorias-elemento',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/CategoriasElemento.vue')
  },
  {
    path: '/categoria-elemento-nuevo',
    name: 'categorias-elemento-nuevo',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/CategoriasElementoEditar.vue')
  },
  {
    path: '/categoria-elemento-editar/:id',
    name: 'categoria-elemento-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/CategoriasElementoEditar.vue')
  },
  {
    path: '/inventarios',
    name: 'inventarios',
    meta: { roles: {
      development: true,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import('../views/Inventarios.vue')
  },
  {
    path: '/inventario-editar/:id/:zona/:tpv',
    name: 'inventario-editar',
    meta: { roles: {
      development: true,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import('../views/InventariosEditar.vue')
  },
  {
    path: '/cuestionario',
    name: 'cuestionario',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['persona']
    } },
    component: () => import('../views/Cuestionario.vue')
  },
  {
    path: '/cuestionario-inventarios',
    name: 'cuestionario-inventarios',
    component: () => import('../views/CuestionarioInventarios.vue')
  },
  {
    path: '/cuestionario-cubrevacaciones',
    name: 'cuestionario-cubrevacaciones',
    component: () => import('../views/CuestionarioCubrevacaciones.vue')
  },
  //{
  //  path: '/cuestionario-navidad',
  //  name: 'cuestionario-navidad',
  //  component: () => import('../views/CuestionarioNavidad.vue')
  //},
  {
    path: '/cuestionarios',
    name: 'cuestionarios',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/CuestionarioMantenimiento.vue')
  },
  {
    path: '/cuestionario-nuevo',
    name: 'cuestionario-nuevo',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/CuestionarioDetalle.vue')
  },
  {
    path: '/cuestionario-editar/:id',
    name: 'cuestionario-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento']
    } },
    component: () => import('../views/CuestionarioDetalle.vue')
  },
  // {
  //   path: '/pedidos-compra',
  //   name: 'pedidos-compra',
  //   component: () => import('../views/Pedidos.vue')
  // },
  // {
  //   path: '/pedido-nuevo',
  //   name: 'pedido-nuevo',
  //   meta: { isNew: true },
  //   component: () => import('../views/PedidosEditar.vue')
  // },
  // {
  //   path: '/pedido-editar/:id',
  //   name: 'pedido-editar',
  //   meta: { isNew: false },
  //   component: () => import('../views/PedidosEditar.vue')
  // },
  {
    path: '/pedidos-venta',
    name: 'pedidos-venta',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['partner']
    } },
    component: () => import('../views/PedidosVenta.vue')
  },
  {
    path: '/pedido-venta-nuevo',
    name: 'pedido-venta-nuevo',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['partner']
    } },
    component: () => import('../views/PedidosVentaEditar.vue')
  },
  {
    path: '/pedido-venta-editar/:id',
    name: 'pedido-venta-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['partner']
    } },
    component: () => import('../views/PedidosVentaEditar.vue')
  },
  {
    path: '/pedidos',
    name: 'pedidos',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import('../views/GestionArticulosTienda/Pedidos_v2.vue')
  },
  {
    path: '/pedido-nuevo',
    name: 'pedido-nuevo',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import('../views/GestionArticulosTienda/PedidosEditar_v2.vue')
  },
  {
    path: '/pedido-editar/:id',
    name: 'pedido-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import('../views/GestionArticulosTienda/PedidosEditar_v2.vue')
  },
  {
    path: '/pedido-editar/:id/articulos',
    name: 'pedido-editar-articulos',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import('../views/GestionArticulosTienda/PedidosEditar_v2_articulos.vue')
  },
  {
    path: '/incidencias-pedidos',
    name: 'incidencias-pedidos',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import('../views/GestionArticulosTienda/IncidenciasPedidos.vue')
  },
  {
    path: '/incidencias-pedido-editar/:id',
    name: 'incidencias-pedido-editar',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import('../views/GestionArticulosTienda/IncidenciasPedidosEditar.vue')
  },
  {
    path: '/devoluciones',
    name: 'devoluciones',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import('../views/GestionArticulosTienda/Devoluciones.vue')
  },
  {
    path: '/devolucion-editar/:id',
    name: 'devolucion-editar',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import('../views/GestionArticulosTienda/DevolucionesEditar.vue')
  },
  {
    path: '/albaranes',
    name: 'albaranes',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import('../views/GestionArticulosTienda/Albaranes.vue')
  },
  {
    path: '/albaran-editar/:id',
    name: 'albaran-editar',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda']
    } },
    component: () => import('../views/GestionArticulosTienda/AlbaranesEditar.vue')
  },
  {
    path: '/solicitud-material',
    name: 'solicitud-material',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda', 'persona']
    } },
    component: () => import('../views/SolicitudMaterial.vue')
  },
  {
    path: '/solicitud-material-responsable',
    name: 'solicitud-material-responsable',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento', 'persona']
    } },
    component: () => import('../views/SolicitudMaterialResponsable.vue')
  },
  {
    path: '/solicitud-material-nuevo',
    name: 'solicitud-material-nuevo',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda', 'persona', 'departamento']
    } },
    component: () => import('../views/SolicitudMaterialEditar.vue')
  },
  {
    path: '/solicitud-material-editar/:id',
    name: 'solicitud-material-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda', 'persona', 'departamento']
    } },
    component: () => import('../views/SolicitudMaterialEditar.vue')
  },
  {
    path: '/solicitud-material-detalle/:id',
    name: 'solicitud-material-detalle',
    meta: { isResp: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda', 'departamento']
    } },
    component: () => import('../views/SolicitudMaterialDetalle.vue')
  },
  {
    path: '/solicitud-material-responsable-detalle/:id',
    name: 'solicitud-material-responsable-detalle',
    meta: { isResp: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['departamento', 'persona']
    } },
    component: () => import('../views/SolicitudMaterialDetalle.vue')
  },
  {
    path: '/solicitud-vacaciones',
    name: 'solicitud-vacaciones',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['persona']
    } },
    component: () => import('../views/SolicitudVacaciones.vue')
  },
  {
    path: '/solicitud-vacaciones-responsable',
    name: 'solicitud-vacaciones-responsable',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['persona', 'departamento']
    } },
    component: () => import('../views/SolicitudVacacionesResponsable.vue')
  },
  {
    path: '/preguntas',
    name: 'preguntas',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda', 'departamento']
    } },
    component: () => import('../views/Preguntas/Preguntas.vue')
  },
  {
    path: '/pregunta-nueva',
    name: 'pregunta-nueva',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda', 'departamento']
    } },
    component: () => import('../views/Preguntas/PreguntasEditar.vue')
  },
  {
    path: '/pregunta-editar/:id',
    name: 'pregunta-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda', 'departamento']
    } },
    component: () => import('../views/Preguntas/PreguntasEditar.vue')
  },
  {
    path: '/pregunta-respuesta/:id',
    name: 'pregunta-respuesta',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda', 'departamento']
    } },
    component: () => import('../views/Preguntas/PreguntasRespuesta.vue')
  },
  {
    path: '/accesos-externos',
    name: 'accesos-externos',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda', 'departamento']
    } },
    component: () => import('../views/AccesosExternos/AccesosExternos.vue')
  },
  {
    path: '/acceso-externo-nuevo',
    name: 'acceso-externo-nuevo',
    meta: { isNew: true, roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda', 'departamento']
    } },
    component: () => import('../views/AccesosExternos/AccesosExternosEditar.vue')
  },
  {
    path: '/acceso-externo-editar/:id',
    name: 'acceso-externo-editar',
    meta: { isNew: false, roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda', 'departamento']
    } },
    component: () => import('../views/AccesosExternos/AccesosExternosEditar.vue')
  },
  {
    path: '/control-acceso-externo',
    name: 'control-acceso-externo',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda', 'departamento']
    } },
    component: () => import('../views/AccesosExternos/ControlAccesoExterno.vue')
  },
  {
    path: '/carnet-manipulador-alimentos',
    name: 'carnet-manipulador-alimentos',
    meta: { roles: {
      development: false,
      andOr: 'OR',
      list: ['tienda', 'departamento']
    } },
    component: () => import('../views/CarnetManipuladorAlimentos.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { guest: true },
    component: () => import(/* webpackChunkName: 'login' */ '../views/Login.vue')
  },
  {
    path: '/cambio-password',
    name: 'cambio-password',
    component: () => import(/* webpackChunkName: 'cambio-password' */ '../views/CambioPassword.vue')
  },
  {
    path: '/logemp',
    name: 'logemp',
    component: () => import(/* webpackChunkName: 'logemp' */ '../views/LogEmp.vue')
  },
  {
    path: '/logstate',
    name: 'logstate',
    component: () => import(/* webpackChunkName: 'logstate' */ '../views/LogState.vue')
  }
]

const router = new VueRouter({
  routes
})

// Comprobaciones al acceder a una ruta
router.beforeEach((to, from, next)=>{
  // Si el usuario no esta logado solamente podrá acceder a la pantalla de login
  if ((to.path === '/' || !to.meta.guest) && (store.state.usuario === '' || store.state.usuario == null))
  {
    return next({
      path: '/login',
      query:{ redirect: to.fullPath }
    });
  }
  else
  {
    if (to.meta.roles != null)
    {
        // Comprobación de roles
        if (!roleListCheck(to.meta.roles))
        {
          return next({
            path: '/',
            query:{ redirect: to.fullPath }
          });
        }
    }

    // Si la ruta no coincide con ninguna de las especificadas se volverá a la Home
    if (to.matched.length === 0) 
    {
      return next({
        path: '/',
        query:{ redirect: to.fullPath }
      });
    }

    return next();
  }
})

function roleListCheck (roles)
{
    // Proteccion rutas desarrollo
    if (roles.development == true && store.state.desarrollo == false)
    {
      return false;
    }

    let isOK = true;
    const isAnd = roles.andOr == 'AND';
    for (const role of roles.list) {
      isOK = checkRole(role);
      if (isOK && !isAnd) break;
      if (!isOK && isAnd) break;
    }

    return isOK;
}

function checkRole(role)
{
  switch(role) {
    case 'departamento':
      return store.state.esDepartamento;
    case 'persona':
      return store.state.esEmpleado;
    case 'tienda':
      return store.state.esTienda;
    case 'partner':
      return store.state.esPartner;
    default:
      return false;
    }
}

export default router
