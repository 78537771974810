<template>
  <v-app>
    <v-app-bar
      app
      v-bind:color="this.$store.state.look.colorFondoBarra"
      :dark="this.$store.state.look.dark"
      clipped-left
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <v-badge :offset-x="look.posBadgetLogoX" :offset-y="look.posBadgetLogoY" :content="numMsjPtesLeer" :value="numMsjPtesLeer" :color="look.colorBadgetLogo" overlap>
          <router-link to="/">
            <v-img
              class = "mt-0"
              alt="Logo"
              contain
              :src = "look.logoMini"
              transition="scale-transition"
              width="90"
              height="62"
            >
            </v-img>
          </router-link>
        </v-badge>

      <v-spacer></v-spacer>
          <v-btn
            class="text-caption mr-n3"
            @click="OpcionesSesion"
            height="100%"
            outlined
            :color="this.$store.state.look.colorTextoCorporativo"
            >
            <div class="ml-n2">
              <div class="mb-1 text-right font-weight-black">
                {{this.$store.state.nombreUsuario}}
              </div>
              <div class="text-right">
                {{this.$store.state.nombreUsuarioAux}}
              </div>
            </div>
              <v-icon
                class="ml-3"
                right
                large
              >
                mdi-account
              </v-icon>
          </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      width="350"
    >
      <v-list class="mt-3" dense>
        <template v-if="$store.state.usuario != '' && $store.state.usuario != ''">

          <template v-if="$store.state.esPartner && $store.state.esProveedor">
              <template v-if="$store.state.desarrollo">
                  <v-list-item link @click="$router.push('proveedor/incidencias')">
                  <v-list-item-action>
                      <v-icon>mdi-alert-box-outline</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                      <v-list-item-title>Incidencias</v-list-item-title>
                  </v-list-item-content>
                  </v-list-item>
              </template>
          </template>
          <template v-else-if="$store.state.esPartner">
            <!-- <template v-if="$store.state.desarrollo"> -->
              <!-- <v-list-item link @click="$router.push('/pedidos-compra')">
                <v-list-item-action>
                  <v-icon>mdi-cart-variant</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Pedidos de compra</v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->

                <v-list-group
                    prepend-icon="mdi-email-sync"
                    :value="false"
                    no-action
                >
                    <template v-slot:activator>
                    <v-list-item-title>Comunicación</v-list-item-title>
                    </template>

                    <v-list-item dense link @click="$router.push('/enviar-mensajes')">
                    <v-list-item-action>
                        <v-icon>mdi-email-send</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Mensajes enviados</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>

                    <v-list-group
                    :value="true"
                    no-action
                    sub-group
                    >
                        <template v-slot:activator>
                        <v-list-item dense>
                            <v-list-item-action>
                            <v-icon>mdi-email-receive</v-icon>
                            </v-list-item-action>                    
                            <v-list-item-content>
                            <v-list-item-title>Mensajes recibidos</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                        </template>

                        <v-list-item dense link @click="llamarRuta('recibir-mensajes', 'Pral', {MsjCatId: 0, MsjCatNom: 'Todos', icon:'mdi-tag'})">
                        <v-list-item-action>
                            <v-icon dense>mdi-tag-multiple</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Todos</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>

                        <v-list-item 
                        v-for="(msjCatObj, i) in msjCat"
                        :key="i"
                        link @click="llamarRuta('recibir-mensajes', 'Pral', msjCatObj)"
                        dense
                        >
                        <v-list-item-action>
                            <v-icon dense>mdi-tag-text</v-icon>
                        </v-list-item-action>

                        <v-list-item-title>{{msjCatObj.MsjCatNom}}</v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                </v-list-group>              

              <v-list-item link @click="$router.push('/pedidos-venta')">
                <v-list-item-action>
                  <v-icon>mdi-cart-variant</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Pedidos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            <!-- </template> -->
          </template>

          <template v-else>

            <template v-if="$store.state.esEmpleado">
                <v-list-item link @click="$router.push('/sugerencias')">
                <v-list-item-action>
                    <v-icon>mdi-lightbulb-on</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Sugerencias</v-list-item-title>
                </v-list-item-content>
                </v-list-item>

                <template v-if="$store.state.esDirDep">
                <v-list-group
                    prepend-icon="mdi-archive-outline"
                    :value="false"
                    no-action
                >
                    <template v-slot:activator>
                    <v-list-item-title>Archivo</v-list-item-title>
                    </template>

                    <v-list-item link @click="$router.push('/categorias-incidencia')">
                      <v-list-item-action>
                          <v-icon>mdi-archive-alert-outline</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                          <v-list-item-title>Categorías de incidencia</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                </v-list-group>
                </template>

                <!-- <template v-if="($store.state.empId != 8 && $store.state.empId != 12) || $store.state.desarrollo"> -->
                <v-list-group
                    prepend-icon="mdi-email-sync"
                    :value="false"
                    no-action
                >
                  <template v-slot:activator>
                      <v-list-item-title>Comunicación</v-list-item-title>
                  </template>

                    <v-list-item link @click="$router.push('/enviar-mensajes')">
                    <v-list-item-action>
                        <v-icon>mdi-email-send</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Mensajes enviados</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>

                    <v-list-group
                        :value="true"
                        no-action
                        sub-group
                    >
                        <template v-slot:activator>
                            <v-list-item dense>
                            <v-list-item-action>
                                <v-icon>mdi-email-receive</v-icon>
                            </v-list-item-action>                    
                            <v-list-item-content>
                            <v-list-item-title>Mensajes recibidos</v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                        </template>

                        <v-list-item dense link @click="llamarRuta('recibir-mensajes', 'Pral', {MsjCatId: 0, MsjCatNom: 'Todos', icon:'mdi-tag'})">
                            <v-list-item-action>
                            <v-icon dense>mdi-email-multiple-outline</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                            <v-list-item-title>Todos</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item 
                            v-for="(msjCatObj, i) in msjCat"
                            :key="i"
                            link @click="llamarRuta('recibir-mensajes', 'Pral', msjCatObj)"
                            dense
                        >
                            <v-list-item-action>
                            <v-icon dense>mdi-tag-text-outline</v-icon>
                            </v-list-item-action>

                            <v-list-item-title>{{msjCatObj.MsjCatNom}}</v-list-item-title>
                        </v-list-item>
                    </v-list-group>

                    <template v-if="$store.state.esEmpleado && $store.state.usuarioAux != ''">
                    <v-list-group
                        :value="true"
                        no-action
                        sub-group
                    >
                        <template v-slot:activator>
                            <v-list-item dense>
                            <v-list-item-action>
                                <v-icon class="red--text text--darken-4">mdi-email-receive</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title class="red--text text--darken-4">Tienda</v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                        </template>

                        <v-list-item dense link @click="llamarRuta('recibir-mensajes', 'Aux', {MsjCatId: 0, MsjCatNom: 'Todos', icon:'mdi-tag'})">
                            <v-list-item-action>
                            <v-icon class="red--text text--darken-4" dense>mdi-email-multiple-outline</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                            <v-list-item-title class="red--text text--darken-4">Todos</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item 
                            v-for="(msjCatObj, i) in msjCat"
                            :key="i"
                            link @click="llamarRuta('recibir-mensajes', 'Aux', msjCatObj)"
                            dense
                        >
                            <v-list-item-action>
                            <v-icon class="red--text text--darken-4" dense>mdi-tag-text-outline</v-icon>
                            </v-list-item-action>

                            <v-list-item-title class="red--text text--darken-4" :v-text="msjCatObj.MsjCatNom"></v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                    </template>
                </v-list-group>            
                <!-- </template> -->

                <v-list-group
                prepend-icon="mdi-account-hard-hat"
                :value="false"
                no-action
                >
                <template v-slot:activator>
                    <v-list-item-title>Jornada laboral</v-list-item-title>
                </template>
                    <v-list-item link @click="$router.push('/cuadrantes-empleado')">
                    <v-list-item-action>
                        <v-icon>mdi-table-clock</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title >Cuadrantes de empleado</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>

                    <template v-if="$store.state.empId==8 || $store.state.empId==12">
                        <v-list-item link @click="$router.push('/fichajes-tienda')">
                        <v-list-item-action>
                            <v-icon>mdi-file-clock-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title >Consulta de fichajes</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-group>

                <!-- <template v-if="($store.state.empId==8 || $store.state.empId==12) || $store.state.desarrollo"> -->
                <v-list-group
                    prepend-icon="mdi-human-greeting"
                    :value="false"
                    no-action
                >
                    <template v-slot:activator>
                    <v-list-item-title>Solicitudes a la empresa</v-list-item-title>
                    </template>

                    <v-list-item link @click="$router.push('/solicitud-material')">
                    <v-list-item-action>
                        <v-icon>mdi-file-document-edit</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title >Solicitud de material</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>

                    <!-- <template v-if="$store.state.desarrollo"> -->
                    <!-- <template v-if="($store.state.empId!= 8 && $store.state.empId!=12) || this.esPersonalTg || $store.state.desarrollo"> -->
                      <v-list-item link @click="$router.push('/solicitud-vacaciones')">
                      <v-list-item-action>
                          <v-icon>mdi-calendar-account</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                          <v-list-item-title >Solicitud de vacaciones</v-list-item-title>
                      </v-list-item-content>
                      </v-list-item>
                    <!-- </template> -->
                    
                    <v-list-item link @click="$router.push('/Cuestionario-cubrevacaciones')" v-show="$store.state.usuario != '' && $store.state.usuario != null && $store.state.esEmpleado && ($store.state.empId == 1 || $store.state.empId == 2 || $store.state.empId == 3 || $store.state.empId == 11 || $store.state.empId == 14)">
                    <v-list-item-action>
                      <v-icon>mdi-format-list-checks</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title >Solicitud cobertura vacaciones</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                    
                    <template>
                        <v-list-item link @click="$router.push('/incidencias')">
                        <v-list-item-action>
                            <v-icon>mdi-alert-box-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Incidencias</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                    </template>

                    <template v-if="$store.state.desarrollo">
                        <v-list-item link @click="$router.push('/mantenimiento/incidencias')">
                        <v-list-item-action>
                            <v-icon>mdi-alert-box-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Incidencias de mantenimiento</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-group>
                <!-- </template> -->

                <template v-if="(($store.state.empId == 1 || $store.state.empId == 2 || $store.state.empId == 3 || $store.state.empId == 8 || $store.state.empId == 10 || $store.state.empId == 11 || $store.state.empId == 12 || $store.state.empId == 14) && 
                    ($store.state.esDirector || $store.state.esJefa || $store.state.esDirDep || $store.state.esDirEje || $store.state.esDirGral || $store.state.desarrollo)) ||
                    (($store.state.empId == 8 || $store.state.empId == 12) && ($store.state.esResZona)) || ($store.state.esDirDep)">
                <v-list-group
                    prepend-icon="mdi-account-tie-outline"
                    :value="false"
                    no-action
                >
                    <template v-slot:activator>
                    <v-list-item-title>Solicitudes de empleados</v-list-item-title>
                    </template>

                    <template v-if="(($store.state.empId == 1 || $store.state.empId == 2 || $store.state.empId == 3 || $store.state.empId == 10 || $store.state.empId == 11 || $store.state.empId == 14) && ($store.state.esDirector || $store.state.esJefa)) ||
                    (($store.state.empId == 8 || $store.state.empId == 12) && ($store.state.esResZona))">
                      <v-list-item link @click="$router.push('/solicitud-material-responsable')">
                      <v-list-item-action>
                          <v-icon>mdi-file-check</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                          <v-list-item-title >Revisión de solicitudes de material</v-list-item-title>
                      </v-list-item-content>
                      </v-list-item>
                    </template>


                    <!-- <template v-if="(($store.state.empId == 1 || $store.state.empId == 2 || $store.state.empId == 3 || $store.state.empId == 11) && ($store.state.esDirector || $store.state.esJefa || $store.state.desarrollo))"> -->
                    <!-- <template v-if="$store.state.desarrollo"> -->
                    <template v-if="(($store.state.empId == 1 || $store.state.empId == 2 || $store.state.empId == 3 || $store.state.empId == 8 || $store.state.empId == 10 || $store.state.empId == 11 || $store.state.empId == 12 || $store.state.empId == 14) && 
                      ($store.state.esDirector || $store.state.esDirDep || $store.state.esDirEje || $store.state.esDirGral || $store.state.desarrollo))"
                    >
                      <v-list-item link @click="$router.push('/solicitud-vacaciones-responsable')">
                          <v-list-item-action>
                          <v-icon>mdi-calendar-check</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                          <v-list-item-title>Revisión de solicitudes de vacaciones</v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>
                    </template>

                    <template v-if="$store.state.esDirDep">
                        <v-list-item link @click="$router.push('/incidencias')">
                        <v-list-item-action>
                            <v-icon>mdi-alert-box-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Incidencias</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                    </template>

                </v-list-group>           
                </template>


                <!-- <template v-if="($store.state.empId != 8 && $store.state.empId != 12) || $store.state.desarrollo"> -->
                <v-list-group
                    prepend-icon="mdi-text-box-outline"
                    :value="false"
                    no-action
                >
                    <template v-slot:activator>
                    <v-list-item-title>Documentación y firmas</v-list-item-title>
                    </template>

                    <v-list-item link @click="$router.push('/documentos-pendientes-firmar')">
                    <v-list-item-action>
                        <v-icon>mdi-draw</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Firmar documentos</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>

                    <v-list-item link @click="$router.push('/documentos-consulta')">
                    <v-list-item-action>
                        <v-icon>mdi-file-eye</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Consultar documentos</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>

                    <template v-if="$store.state.desarrollo">
                      <v-list-item link @click="$router.push('/nominas-consulta')">
                        <v-list-item-action>
                            <v-icon>mdi-file-eye</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Consultar nóminas</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>

                </v-list-group>
                <!-- </template> -->

                <!-- <template v-if="($store.state.empId != 8 && $store.state.empId != 12) || $store.state.desarrollo"> -->
                <v-list-item link @click="$router.push('/documentos-empresa')">
                    <v-list-item-action>
                    <v-icon>mdi-file-document-multiple</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                    <v-list-item-title>Documentos de empresa</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- </template> -->
            </template>

            <template v-else-if="$store.state.esTienda && ((this.$store.state.usuarioAux != null && this.$store.state.usuarioAux != '') || !this.$store.state.emp.UsrAuxReqTda)">
                <v-list-item dense link @click="$router.push('/sugerencias')">
                <v-list-item-action>
                    <v-icon>mdi-lightbulb-on</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Sugerencias</v-list-item-title>
                </v-list-item-content>
                </v-list-item>

                <template v-if="$store.state.empId==8 || $store.state.empId==12">
                <v-list-item dense link @click="$router.push('/extranet-martin')">
                    <v-list-item-action>
                    <v-icon>mdi-microsoft-internet-explorer</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                    <v-list-item-title>Extranet Martín Martín</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                </template>

                <!-- <template v-if="($store.state.empId != 8 && $store.state.empId != 12) || $store.state.desarrollo"> -->
                <v-list-group
                    prepend-icon="mdi-email-sync"
                    :value="false"
                    no-action
                >
                    <template v-slot:activator>
                    <v-list-item-title>Comunicación</v-list-item-title>
                    </template>

                    <v-list-item dense link @click="$router.push('/enviar-mensajes')">
                    <v-list-item-action>
                        <v-icon>mdi-email-send</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Mensajes enviados</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>

                    <v-list-group
                    :value="true"
                    no-action
                    sub-group
                    >
                        <template v-slot:activator>
                        <v-list-item dense>
                            <v-list-item-action>
                            <v-icon>mdi-email-receive</v-icon>
                            </v-list-item-action>                    
                            <v-list-item-content>
                            <v-list-item-title>Mensajes recibidos</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                        </template>

                        <v-list-item dense link @click="llamarRuta('recibir-mensajes', 'Pral', {MsjCatId: 0, MsjCatNom: 'Todos', icon:'mdi-tag'})">
                        <v-list-item-action>
                            <v-icon dense>mdi-tag-multiple</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Todos</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>

                        <v-list-item 
                        v-for="(msjCatObj, i) in msjCat"
                        :key="i"
                        link @click="llamarRuta('recibir-mensajes', 'Pral', msjCatObj)"
                        dense
                        >
                        <v-list-item-action>
                            <v-icon dense>mdi-tag-text</v-icon>
                        </v-list-item-action>

                        <v-list-item-title>{{msjCatObj.MsjCatNom}}</v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                    
                    <v-list-item v-if="$store.state.esTienda" link @click="$router.push('/preguntas')">
                        <v-list-item-action>
                            <v-icon>mdi-comment-question-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Preguntas</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <!-- </template> -->

                <v-list-group
                prepend-icon="mdi-account-hard-hat"
                :value="false"
                no-action
                >
                <template v-slot:activator>
                    <v-list-item-title>Jornada laboral</v-list-item-title>
                </template>

                <template v-if="$store.state.empId == 8 || $store.state.empId == 12">
                    <v-list-item link @click="$router.push('/fichaje')">
                        <v-list-item-action>
                        <v-icon>mdi-account-clock-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                        <v-list-item-title >Fichaje</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>

                <template v-if="$store.state.empId == 8 || $store.state.empId == 12">
                    <v-list-item link @click="$router.push('/fichajes-tienda')">
                    <v-list-item-action>
                        <v-icon>mdi-file-clock-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title >Consulta de fichajes</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </template>

                <v-list-item link @click="$router.push('/cuadrantes-tienda')">
                    <v-list-item-action>
                    <v-icon>mdi-table-clock</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                    <v-list-item-title >Cuadrantes de tienda</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                </v-list-group>

                <v-list-group
                prepend-icon="mdi-speedometer"
                :value="false"
                no-action
                >
                <template v-slot:activator>
                    <v-list-item-title>Controles en tienda</v-list-item-title>
                </template>
                
                <template v-if="$store.state.empId == 8 || $store.state.empId == 12">
                    <v-list-item link @click="$router.push('/control-temperatura')">
                    <v-list-item-action>
                        <v-icon>mdi-thermometer-alert</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title >Temperatura</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-else>
                    <v-list-item link @click="$router.push('/control-temperatura2')">
                    <v-list-item-action>
                        <v-icon>mdi-thermometer-alert</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title >Temperatura</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </template>

                <template v-if="$store.state.empId == 8 || $store.state.empId == 12">
                    <v-list-item link @click="$router.push('/control-trazabilidad')">
                    <v-list-item-action>
                        <v-icon>mdi-tag-plus-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title >Trazabilidad</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-else>
                    <v-list-item link @click="$router.push('/control-trazabilidad2')">
                    <v-list-item-action>
                        <v-icon>mdi-tag-plus-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title >Trazabilidad</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </template>

                <template v-if="$store.state.empId == 8 || $store.state.empId == 12">
                    <v-list-item link @click="$router.push('/consulta-trazabilidad')">
                    <v-list-item-action>
                        <v-icon>mdi-tag-multiple-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title >Consulta trazabilidad</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-else>
                    <v-list-item link @click="$router.push('/consulta-trazabilidad2')">
                    <v-list-item-action>
                        <v-icon>mdi-tag-multiple-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title >Consulta trazabilidad</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </template>

                <!-- <template v-if="$store.state.empId != 8 && $store.state.empId != 12"> -->
                <v-list-item link @click="$router.push('/control-limpieza')">
                <v-list-item-action>
                    <v-icon>mdi-vacuum-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Control de limpieza externa</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                <!-- </template> -->

                <template v-if="$store.state.desarrollo">
                <v-list-item link @click="$router.push('/control-dinero')">
                <v-list-item-action>
                    <v-icon>mdi-cash-register</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Control de dinero</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                </template>
                
                <template v-if="$store.state.desarrollo || $store.state.dir.DirId == 95 || $store.state.dir.DirId == 19 || $store.state.dir.DirId == 11 || $store.state.dir.DirId == 228 || $store.state.dir.DirId == 134 || $store.state.dir.DirId == 111 || $store.state.dir.DirId == 126 || $store.state.dir.DirId == 123 || $store.state.dir.DirId == 118 || $store.state.dir.DirId == 120">
                  <v-list-item link @click="$router.push('/control-carteleria')">
                  <v-list-item-action>
                      <v-icon>mdi-printer</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                      <v-list-item-title>Imprimir Carteleria</v-list-item-title>
                  </v-list-item-content>
                  </v-list-item>
                </template>

                <template>
                <v-list-item link @click="$router.push('/incidencias')">
                <v-list-item-action>
                    <v-icon>mdi-alert-box-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Incidencias</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                </template>

                <!-- <template v-if="($store.state.empId == 8 || $store.state.empId == 12) ||  $store.state.desarrollo"> -->
                    <v-list-item link @click="$router.push('/presupuesto')">
                    <v-list-item-action>
                        <v-icon>mdi-currency-eur</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Presupuesto</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                <!-- </template> -->
                
                <template v-if="$store.state.desarrollo">
                  <v-list-item link @click="$router.push('/inventarios')">
                    <v-list-item-action>
                      <v-icon>mdi-format-list-checks</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Inventarios</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-list-item v-if="$store.state.desarrollo" link @click="$router.push('/control-acceso-externo')">
                          <v-list-item-action>
                            <v-icon>mdi-smart-card-outline</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Accesos externos</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
              
                        <v-list-item link @click="$router.push('/carnet-manipulador-alimentos')">
                          <v-list-item-action>
                            <v-icon>mdi-badge-account-horizontal-outline</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Carnet manipulador alimentos</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                </v-list-group>

               

            <!-- v-if="$store.state.desarrollo || perId == 1069 || perId == 1158 || perId == 1077" -->
            <v-list-group  v-if="$store.state.desarrollo || perId == 1069 || perId == 38968 || perId == 1077 || perId == 1153 || perId == 1171 || perId == 1190 || perId == 1204" 
                prepend-icon="mdi-store"
                :value="false"
                no-action
                >
                <template v-slot:activator>
                    <v-list-item-title>Gestión articulos tienda</v-list-item-title>
                </template>
                 <!--<v-list-item link @click="$router.push('/pedidos-compra')"> -->
                <v-list-item link @click="$router.push('/pedidos')">
                  <v-list-item-action>
                    <v-icon>mdi-cart-variant</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <!--<v-list-item-title>Pedidos de compra</v-list-item-title>-->
                    <v-list-item-title>Pedidos</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <!-- <v-list-item link @click="$router.push('/pedidos-new')">
                  <v-list-item-action>
                    <v-icon>mdi-cart-variant</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title>Pedidos de compra</v-list-item-title>
                    <v-list-item-title>Pedidos new</v-list-item-title>
                  </v-list-item-content>
                </v-list-item> -->

                <v-list-item link @click="$router.push('/incidencias-pedidos')" v-show="$store.state.usuario != '' && $store.state.usuario != null && ($store.state.empId == 1 || $store.state.empId == 2 || $store.state.empId == 11 || $store.state.empId == 14)">
                  <v-list-item-action>
                    <v-icon>mdi-store-alert</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Incidencias</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                
                <v-list-item link @click="$router.push('/devoluciones')">
                  <v-list-item-action>
                    <v-icon>mdi-cart-remove</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Devoluciones</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="$store.state.desarrollo || $store.state.empId == 8 || $store.state.empId == 12 || $store.state.empId == 15" link @click="$router.push('/albaranes')">
                  <v-list-item-action>
                    <v-icon>mdi-clipboard-file</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Albaranes</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </v-list-group>
          

                <!-- <template v-if="($store.state.empId != 8 && $store.state.empId != 12) || $store.state.desarrollo"> -->
                <v-list-item link @click="$router.push('/documentos-empresa')">
                    <v-list-item-action>
                    <v-icon>mdi-file-document-multiple</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                    <v-list-item-title>Documentos de empresa</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- </template> -->
            </template>

            <template v-else-if="$store.state.esDepartamento">
                <v-list-item link @click="$router.push('/sugerencias')">
                <v-list-item-action>
                    <v-icon>mdi-lightbulb-on</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Sugerencias</v-list-item-title>
                </v-list-item-content>
                </v-list-item>

                <template v-if="($store.state.esRRHH && $store.state.empId != 6) || $store.state.desarrollo">
                <v-list-group
                    prepend-icon="mdi-archive-outline"
                    :value="false"
                    no-action
                >
                    <template v-slot:activator>
                    <v-list-item-title>Archivo</v-list-item-title>
                    </template>

                    <v-list-item link @click="$router.push('/categorias-material')">
                    <v-list-item-action>
                        <v-icon>mdi-hanger</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title >Categorías de material</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>

                    <v-list-item link @click="$router.push('/variantes-material')">
                    <v-list-item-action>
                        <v-icon>mdi-animation-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Variantes de material</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>

                    <v-list-item link @click="$router.push('/materiales')">
                    <v-list-item-action>
                        <v-icon>mdi-tshirt-crew-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title >Material y vestuario</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>

                    <v-list-item link @click="$router.push('/control-limpieza')">
                      <v-list-item-action>
                          <v-icon>mdi-vacuum-outline</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                          <v-list-item-title>Control de limpieza</v-list-item-title>
                      </v-list-item-content>
                      </v-list-item>

                    <template v-if="$store.state.desarrollo">
                      <v-list-item link @click="$router.push('/categorias-articulo')">
                      <v-list-item-action>
                          <v-icon>mdi-basket-fill</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                          <v-list-item-title >Categorías de artículo</v-list-item-title>
                      </v-list-item-content>
                      </v-list-item>

                      <template v-if="$store.state.desarrollo">
                        <v-list-item link @click="$router.push('/articulos')">
                          <v-list-item-action>
                            <v-icon>mdi-basket-outline</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title >Artículos</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                      <v-list-item link @click="$router.push('/categorias-persona')">
                        <v-list-item-action>
                          <v-icon>mdi-briefcase-account-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Categorías de persona</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>


                        <v-list-item link @click="$router.push('/personas')">
                          <v-list-item-action>
                            <v-icon>mdi-account-cog-outline</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Personas</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <v-list-item link @click="$router.push('/almacenes')">
                        <v-list-item-action>
                          <v-icon>mdi-warehouse</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Almacenes</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item link @click="$router.push('/direcciones')">
                        <v-list-item-action>
                          <v-icon>mdi-map-marker-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Direcciones</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item link @click="$router.push('/maquinas')">
                        <v-list-item-action>
                          <v-icon>mdi-thermometer-lines</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Máquinas</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item link @click="$router.push('/estados-solicitud')">
                        <v-list-item-action>
                          <v-icon>mdi-table-cog</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Estados de solicitud</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item link @click="$router.push('/estados-vacaciones')">
                        <v-list-item-action>
                          <v-icon>mdi-application-cog-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Estados de vacaciones</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item link @click="$router.push('/periodos')">
                        <v-list-item-action>
                          <v-icon>mdi-calendar-clock</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Periodos</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      

                      <v-list-item link @click="$router.push('/categorias-elemento')">
                        <v-list-item-action>
                          <v-icon>mdi-inbox-multiple-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Categorías de elemento</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item link @click="$router.push('/elementos')">
                        <v-list-item-action>
                          <v-icon>mdi-spray-bottle</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Elementos</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item link @click="$router.push('/cuestionarios')">
                        <v-list-item-action>
                          <v-icon>mdi-clipboard-list-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Cuestionarios</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      
                      <template v-if="$store.state.desarrollo" >
                        <v-list-item link @click="$router.push('/accesos-externos')">
                          <v-list-item-action>
                            <v-icon>mdi-account-cog-outline</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Accesos externos</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <!-- <v-list-item link @click="$router.push('/categorias-incidencia')">
                      <v-list-item-action>
                          <v-icon>mdi-archive-alert-outline</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                          <v-list-item-title>Categorías de incidencia</v-list-item-title>
                      </v-list-item-content>
                      </v-list-item> -->

                      <!-- <v-list-item link @click="$router.push('/incidencias')">
                      <v-list-item-action>
                          <v-icon>mdi-alert-box-outline</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                          <v-list-item-title>Incidencias</v-list-item-title>
                      </v-list-item-content>
                      </v-list-item> -->

                      <!-- <v-list-item link @click="$router.push('/pedidos-compra')">
                      <v-list-item-action>
                          <v-icon>mdi-cart-variant</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                          <v-list-item-title>Pedidos de compra</v-list-item-title>
                      </v-list-item-content>
                      </v-list-item> -->
                    </template>
                </v-list-group>
                </template>

                <template v-if="$store.state.empId==8 || $store.state.empId==12">
                  <v-list-item link @click="$router.push('/extranet-martin')">
                      <v-list-item-action>
                      <v-icon>mdi-microsoft-internet-explorer</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                      <v-list-item-title>Extranet Martín Martín</v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
                </template>

                <!-- <template v-if="($store.state.empId != 8 && $store.state.empId != 12) || $store.state.desarrollo"> -->
                <v-list-group
                    prepend-icon="mdi-email-sync"
                    :value="false"
                    no-action
                >
                    <template v-slot:activator>
                    <v-list-item-title>Comunicación</v-list-item-title>
                    </template>
                    <v-list-item link @click="$router.push('/enviar-mensajes')">
                    <v-list-item-action>
                        <v-icon>mdi-email-send</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Mensajes enviados</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                    <v-list-group
                    :value="true"
                    no-action
                    sub-group
                    >
                    <template v-slot:activator>
                        <v-list-item dense>
                        <v-list-item-action>
                            <v-icon>mdi-email-receive</v-icon>
                        </v-list-item-action>                    <v-list-item-content>
                        <v-list-item-title>Mensajes recibidos</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                    </template>

                    <v-list-item dense link @click="llamarRuta('recibir-mensajes', 'Pral', {MsjCatId: 0, MsjCatNom: 'Todos', icon:'mdi-tag'})">
                        <v-list-item-action>
                        <v-icon dense>mdi-email-multiple-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                        <v-list-item-title>Todos</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item 
                        v-for="(msjCatObj, i) in msjCat"
                        :key="i"
                        link @click="llamarRuta('recibir-mensajes', 'Pral', msjCatObj)"
                        dense
                    >
                        <v-list-item-action>
                        <v-icon dense>mdi-tag-text-outline</v-icon>
                        </v-list-item-action>

                        <v-list-item-title>{{msjCatObj.MsjCatNom}}</v-list-item-title>
                    </v-list-item>
                    </v-list-group>

                    <v-list-item v-if="$store.state.desarrollo || $store.state.esDepartamento || $store.state.esDirector" link @click="$router.push('/preguntas')">
                        <v-list-item-action>
                            <v-icon>mdi-comment-question-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Preguntas</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <!-- </template> -->

                <template v-if="$store.state.empId != 6">
                  <v-list-group
                  prepend-icon="mdi-account-hard-hat"
                  :value="false"
                  no-action
                  >
                  <template v-slot:activator>
                      <v-list-item-title>Jornada laboral</v-list-item-title>
                  </template>

                  <v-list-item link @click="$router.push('/cuadrantes-tienda')">
                      <v-list-item-action>
                      <v-icon>mdi-table-clock</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                      <v-list-item-title >Cuadrantes de tienda</v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>

                  <v-list-item link @click="$router.push('/cuadrantes-empleado')">
                      <v-list-item-action>
                      <v-icon>mdi-table-clock</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                      <v-list-item-title >Cuadrantes de empleado</v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>

                  <template v-if="($store.state.empId == 8 || $store.state.empId == 12 || $store.state.empId == 10)">
                      <v-list-item link @click="$router.push('/fichajes-tienda')">
                      <v-list-item-action>
                          <v-icon>mdi-file-clock-outline</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                          <v-list-item-title >Consulta de fichajes</v-list-item-title>
                      </v-list-item-content>
                      </v-list-item>
                  </template>         

                  <template v-if="$store.state.desarrollo">
                      <v-list-item link @click="$router.push('/cuadrantes-gestion')">
                      <v-list-item-action>
                          <v-icon>mdi-clock-edit</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                          <v-list-item-title>Gestión de cuadrantes</v-list-item-title>
                      </v-list-item-content>
                      </v-list-item>
                  </template>      
                  </v-list-group>
                </template>

              <!-- <template v-if="$store.state.esRRHH && $store.state.empId != 6 || $store.state.desarrollo"> -->
              <template>
                <v-list-group
                    prepend-icon="mdi-account-tie-outline"
                    :value="false"
                    no-action
                >
                    <template v-slot:activator>
                    <v-list-item-title>Solicitudes de empleados</v-list-item-title>
                    </template>

                    <template v-if="$store.state.esRRHH && $store.state.empId != 6">
                        <v-list-item link @click="$router.push('/solicitud-material-responsable')">
                        <v-list-item-action>
                            <v-icon>mdi-file-check</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title >Revisión de solicitudes de material</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                    </template>

                    <template v-if="$store.state.esRRHH && $store.state.empId != 6">
                      <v-list-item link @click="$router.push('/solicitud-vacaciones-responsable')">
                          <v-list-item-action>
                          <v-icon>mdi-calendar-check</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                          <v-list-item-title>Revisión de solicitudes de vacaciones</v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>
                    </template>

                    <template>
                    <v-list-item link @click="$router.push('/incidencias')">
                      <v-list-item-action>
                          <v-icon>mdi-alert-box-outline</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                          <v-list-item-title>Incidencias</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    </template>
                </v-list-group>           
              </template>



              <template v-if="$store.state.empId != 6">
                <v-list-item link @click="$router.push('/documentos-empresa')">
                    <v-list-item-action>
                    <v-icon>mdi-file-document-multiple</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                    <v-list-item-title>Documentos de empresa</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
              </template>

              <template v-if="$store.state.empId != 6">
                <v-list-item link @click="$router.push('/documentos-empresa-visualizacion')">
                    <v-list-item-action>
                    <v-icon>mdi-file-eye</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                    <v-list-item-title>Visualización de documentos</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
              </template>
            </template>

            <template v-if="$store.state.esTiendaCliente">          
                <v-list-group
                prepend-icon="mdi-store"
                :value="false"
                no-action
                >
                    <template v-slot:activator>
                        <v-list-item-title>Gestión articulos tienda</v-list-item-title>
                    </template>
                    <!-- <v-list-item link @click="$router.push('/pedidos-compra')"> -->
                    <v-list-item link @click="$router.push('/pedidos')">
                      <v-list-item-action>
                        <v-icon>mdi-cart-variant</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <!-- <v-list-item-title>Pedidos de compra</v-list-item-title> -->
                        <v-list-item-title>Pedidos</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item link @click="$router.push('/incidencias-pedidos')">
                      <v-list-item-action>
                        <v-icon>mdi-store-alert</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Incidencias</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    
                    <v-list-item link @click="$router.push('/devoluciones')">
                      <v-list-item-action>
                        <v-icon>mdi-cart-remove</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Devoluciones</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item link @click="$router.push('/albaranes')">
                      <v-list-item-action>
                        <v-icon>mdi-clipboard-file</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Albaranes</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </template>

          </template>
        </template>

        <v-list-group
          prepend-icon="mdi-account"
          :value="false"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title>Usuario</v-list-item-title>
          </template>

          <v-list-item link @click="$router.push('/Login')" v-show="$store.state.usuario == '' || $store.state.usuario == null">
            <v-list-item-action>
              <v-icon>mdi-login</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >Iniciar sesión</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="$router.push('/Gestion-Rgpd')" v-show="$store.state.usuario != '' && $store.state.usuario != null && $store.state.esEmpleado">
            <v-list-item-action>
              <v-icon>mdi-format-list-checks</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >Consentimiento RGPD</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          
          <v-list-item link @click="$router.push('/Cuestionario')" v-show="$store.state.usuario != '' && $store.state.usuario != null && $store.state.esEmpleado && ($store.state.empId == 1 || $store.state.empId == 2 || $store.state.empId == 10 || $store.state.empId == 11 || $store.state.empId == 14)">
            <v-list-item-action>
              <v-icon>mdi-format-list-checks</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >Fiesta El Rincon objetivos 2023</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          
          <v-list-item link @click="$router.push('/Cuestionario-inventarios')" v-show="$store.state.usuario != '' && $store.state.usuario != null && $store.state.esEmpleado && ($store.state.empId == 1 || $store.state.empId == 2 || $store.state.empId == 3 || $store.state.empId == 11 || $store.state.empId == 14)">
            <v-list-item-action>
              <v-icon>mdi-format-list-checks</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >Inscripción inventarios</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          
          <!--
          <v-list-item link @click="$router.push('/cuestionario-navidad')" v-show="$store.state.usuario != '' && $store.state.usuario != null && $store.state.esEmpleado && ($store.state.empId == 1 || $store.state.empId == 2 || $store.state.empId == 3 || $store.state.empId == 11 || $store.state.empId == 14)">
            <v-list-item-action>
              <v-icon>mdi-format-list-checks</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >Lotería Navidad</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          -->
          <v-list-item link @click="$router.push('/cambio-password')" v-show="$store.state.usuario != '' && $store.state.usuario != null && $store.state.esEmpleado">
            <v-list-item-action>
              <v-icon>mdi-form-textbox-password</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >Cambio de contraseña</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="$router.push('LogEmp')" v-show="$store.state.usuario != '' && $store.state.usuario != null">
            <v-list-item-action>
              <v-icon>mdi-account-details-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >Datos de empresa</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="$router.push('/LogState')" v-show="$store.state.usuario != '' && $store.state.usuario != null">
            <v-list-item-action>
              <v-icon>mdi-account-details-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >Datos de usuario</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item link @click="$router.push('pruebas-front')" v-show="$store.state.usuario != '' && $store.state.usuario != null  && !$store.state.desarrollo">
            <v-list-item-action>
              <v-icon>mdi-account-details-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >Pruebas Front</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
  import axios from "axios"
  import { mapState } from "vuex";

  export default {
  name: 'App',

  components: {
  },
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz', 'perIdAux', 'esTienda', 'numMsjPtesLeer', 'look']),
    },
  data: () => ({
    drawer: null,
    msjCat: [],
    msjPtesLeerVisible: true,
    intervalId: 0,
    esPersonalTg: false
  }),
  mounted() {
    window.document.title = this.look.Titulo
    this.$store.commit('localSet', false);
    this.$store.commit('desarrolloSet', false);
     this.$store.dispatch('AsignarUsuario').then (() => {
      // Inicio código notificaciones
      // navigator.serviceWorker.register('service-worker.js').then(registration => {

      //   var pkey;

      //   if (this.$store.state.local) {
      //     pkey = "BI3oLNWthg6RkZxxIHBwTnauTDfl__BdE3ODCTnkxuThXsTfk2AxneyB-TdcThz9fp04E6C26-YhDBEK6EcdVpM";
      //   }
      //   else {
      //     pkey = "BBXvcBvP9OHOep4LEvbgcZJ8AL_IN8s87mSnPB_UQPYLyBMJnMY-oaW3RoLT5imsURJ3iReKeCszLyppaA-8uTg";
      //   }

      //   const vapidKeyArray = urlBase64ToUint8Array(pkey);
      //   registration.pushManager.subscribe({
      //       userVisibleOnly: true,
      //       applicationServerKey: vapidKeyArray
      //     }).then(subscription => {

      //     const publicKey = generatePublicKey(subscription);
      //     const authKey = generateAuthKey(subscription);

      //     var controllerParameters = {
      //               Action: "SAVE_DEV",
      //               devEndPoint: subscription.endpoint,
      //               devPubKey: publicKey,
      //               devAutKey: authKey
      //     }

      //     var AuthToken = localStorage.getItem('token');
      //     axios({ method: "POST", "url": this.urlRaiz + "/api/dev", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
      //         .then(result => {
      //           console.log(result);
      //         })

      //     console.log(subscription.endpoint, publicKey, authKey);
      //   })
      // })        
      // Fin código notificaciones

      //  alert("Antes");
      //  let registerScript = document.createElement("script");
      //  registerScript.setAttribute('src', 'register.js');
      //  document.body.append('registerScript');
      //  alert("Después");

        this.$store.dispatch('AsignarUsuarioAux').then(() => {
          this.loadMsjCat();
          this.ActualizarMensajes();
          if (this.esTienda)
          {
            this.$store.dispatch('GetPreguntasPendientes');
          }
          this.esPersonalTg = this.$store.state.per.PerCatLevels.find(x=> x.PerCatId == 394) != null;
      }) ;
     })
     .catch(error => {
       alert(error);
     });
     this.intervalId = setInterval(this.ActualizarMensajes, 900000);
  },
  created(){
    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
    let lookParam = params.get('look');

    if (lookParam != null)
    {
      this.$store.commit('lookSet', {nombreLook: lookParam, empId: 0});
    }
  },
  unmounted() {
    clearInterval(this.intervalId);
  },
  methods: {
    ActualizarMensajes() {
      this.$store.dispatch('GetMensajes');
    },
    llamarRuta(rutaDestino, valorPropiedad, msjCat) {
      if (valorPropiedad == 'Pral') {
          this.$router.push({ name: 'recibir-mensajes-pral-' + msjCat.MsjCatId })
      }
      else if (valorPropiedad == 'Aux') {
          this.$router.push({ name: 'recibir-mensajes-aux-' + msjCat.MsjCatId })
      }
    },
    CerrarSesion: function () {
        this.$store.dispatch('DesAsignarUsuario');
        this.$store.dispatch('DesAsignarUsuarioAux');
        this.$router.push('/');
    },
    OpcionesSesion: function() {
      if (this.$store.state.usuario==''){
        this.$router.push('/Login')      
      }
      else{
        this.$router.push('/LogState');
      }
    },
    botonSesionLabel: function() {
      if (this.$store.state.usuario === ''){
        return 'Iniciar sesión'
      }
      else{
        var nombreUsuarioCompleto = this.$store.state.nombreUsuario
        if (this.$store.state.nombreUsuarioAux != null && this.$store.state.nombreUsuarioAux != '') {
          nombreUsuarioCompleto = nombreUsuarioCompleto + ' <br> ' + this.$store.state.nombreUsuarioAux
        }
        return nombreUsuarioCompleto;
      }
    },
    loadMsjCat() {
      var controllerParameters = {   
              Action: 'GET_DATA_LIST_PAGINATED',
              CampoBusqueda: 'MsjCatNom',
              ValorBuscar: '',
              NumRegsPag: 35,
              NumPag: 1,
              EmpId: this.empId,
              MsjCatIdParent: null,
              MayMin: 'MAYUSCULA_MINUSCULAS'
          } ;       

      var AuthToken = localStorage.getItem('token');  
        axios({ method: "POST", "url": this.urlRaiz + "/api/MsjCat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
            this.msjCat = [];

            result.data.EntsList.forEach(element => {
              var itemObject = {MsjCatId: element.MsjCatId, MsjCatNom: element.MsjCatNom, icon: 'mdi-tag'}
              this.msjCat.push(itemObject);
            });
      });
    },
    // test() {
    //   console.log('test msg')
    // }
  }  
};
</script>
